import React, {useState, useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';

export function Autocomplete({placeholder, onSuggestionSelected, buildUrl, transformSuggestionsResponse, displaySuggestion}) {

    const [searchInput, setSearchInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSuggestionsHidden, setIsSuggestionsHidden] = useState(true);

    useEffect(() => {
        const timeoutRef = setTimeout(() => {
            setLoading(true);
            const url = buildUrl(searchInput);
            fetch(url)
                .then(res => res.json())
                .then(json => {
                    setLoading(false);
                    setSuggestions(transformSuggestionsResponse(json));
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    setSuggestions([]);
                });
        }, 300)
        return () => clearTimeout(timeoutRef);
    }, [searchInput]);

    const selectedSuggestion = (suggestion) => {
        setSearchInput(displaySuggestion(suggestion))
        setIsSuggestionsHidden(true);
        onSuggestionSelected(suggestion);
    }

    return (
        <form className="maif-form" autoComplete="off" onSubmit={e => e.preventDefault()}>
            <div className="form-group">
                <div className="field-item champ-autocomplete">
                    <label className="sr-only" for="field-autocomplete-commune">Champ de recherche</label>
                    <input id="field-autocomplete-commune"
                           name={uuidv4()}
                           type="text"
                           autoComplete="off"
                           aria-autocomplete="list"
                           role="combobox"
                           aria-owns="liste-options-field-autocomplete"
                           required="required"
                           placeholder={placeholder}
                           value={searchInput}
                           onFocus={() => setIsSuggestionsHidden(false)}
                           onChange={(e) => setSearchInput(e.target.value)}/>
                    {searchInput.length > 0 && !loading &&
                        <button className="reset"
                                type="button"
                                title="Effacer le contenu"
                                onClick={() => setSearchInput("")}>
                            <i className="maificon maificon-close" aria-hidden="true"></i>
                            <div className="sr-only">Effacer le contenu</div>
                        </button>
                    }
                    {searchInput.length > 0 && loading &&
                        <div className="loader-wrap">
                            <div className="loader" title="Chargement des résultats en cours"></div>
                        </div>
                    }
                    <ul className={`dropdown-menu ${isSuggestionsHidden ? '' : 'show'}`}
                        id="liste-options-field-autocomplete"
                        role="listbox"
                        aria-label="Suggestions de saisie">
                        {suggestions.map((suggestion, index) => {
                            return (<li className="dropdown-item"
                                role="option"
                                tabIndex="-1"
                                aria-selected="false"
                                id={"field-autocomplete-" + index}
                                onClick={() => selectedSuggestion(suggestion)}>
                                <span>{displaySuggestion(suggestion)}</span>
                            </li>);
                        })}
                    </ul>
                    <div className="sr-only" aria-live="polite" aria-atomic="true">
                        <p>{suggestions.length} résultats disponibles.</p>
                    </div>
                </div>
            </div>
        </form>
    )
}